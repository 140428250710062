export default [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'LFP',
    value: 'LFP',
  },
  {
    label: 'LiMn2O4',
    value: 'LiMn2O4',
  },
  {
    label: 'NCA',
    value: 'NCA',
  },
  {
    label: 'NaCl',
    value: 'NaCl',
  },
  {
    label: 'NMC 811',
    value: 'NMC 811',
  },
  {
    label: 'NMC 622',
    value: 'NMC 622',
  },
  {
    label: 'NMC 111',
    value: 'NMC 111',
  },
  {
    label: 'NiMH',
    value: 'NiMH',
  },
  {
    label: 'Other',
    value: 'Other',
  }
];
